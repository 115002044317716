@import "$colors";

footer {
  background: $gradient-footer;
  min-height: 179px;

  a {
    text-decoration: none;
  }

  a:hover {
    color: white;
  }
}
