@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../assets/fonts/Ubuntu-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Druk Text Wide Cyr";
  src: url("../assets/fonts/Druk-Text-Wide-Cyr-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothaProBol.ttf") format("truetype");
  font-weight: 700;
}


@font-face {
  font-family: "Gotham Pro";
  src: url("../assets/fonts/GothaProReg.ttf") format("truetype");
  font-weight: 400;
}


