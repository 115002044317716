header {
  .emailName {
    .MuiTypography-root {
      max-width: 140px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
