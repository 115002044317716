
@function get($map, $key) {
  @return map-get($map, $key);
}


$white: #FFFFFF;
$black: rgba(0,0,0,1);

$primary: rgba(86, 6, 255, 1);
$purple-4: rgba(221,88,224,1);
$gray-1: rgba(246,247,254,1);
$gray-2: #C6C6F3;
$gray-9: rgba(26,46,53,1);
$red-5: rgba(247,89,99,1);
$red-6: rgba(225,76,125,1);
$blue-1: rgba(162,189,252,1);
$blue-2: rgba(91,158,240,1);
$blue-4: rgba(96,120,249,1);
$blue-3: rgba(165,122,255,1);
$blue-6: rgba(111,78,222,1);
$blue-10: #6D6BE2;
$gray-10: rgba(0,0,0,1);
$gray-9: rgba(32,32,55,1);
$gray-8: rgba(47,47,70,1);
$gray-7: rgba(88,88,116,1);
$gray-6: rgba(122,122,148,1);
$gray-5: rgba(153,153,173,1);
$gray-4: rgba(185,185,203,1);
$gray-3: #DBD7F0;

$green-10: rgba(10, 185, 125, 1);
$green-9: rgba(9, 166, 112, 1);
$green-8: rgba(8, 148, 100, 1);
$green-7: rgba(7, 129, 87, 1);
$green-6: rgba(6, 111, 75, 1);
$green-5: rgba(5, 92, 62, 1);
$green-4: rgba(4, 74, 50, 1);
$green-3: rgba(3, 55, 37, 1);
$green-2: rgba(2, 37, 25, 1);
$green-1: rgba(1, 18, 12, 1);

$backgroundColor: #F6F7FE;

$purple-6: #AC01E9;
$white-50: rgba(255, 255, 255, 0.58);




$blue-3-gray-1: linear-gradient(to bottom, rgba(165,122,255,0.646) 0%,rgba(198,198,243,0.776) 100%);
$blue-3-purple-4: linear-gradient(to bottom, rgba(162,189,252,0.635) 26.08%,rgba(221,88,224,0.868) 100%);
$info-card-free: linear-gradient(to bottom, rgba(111,78,222,0.435) 0%,rgba(92,159,241,0.361) 100%);
$blue-2-purple-4: linear-gradient(to bottom, rgba(92,159,241,0.361) 0%,rgba(221,88,224,0.867) 100%);
$gradient-nft: linear-gradient(180deg, #FFFFFF 0%, #68EFFF 100%);
$gradient-info: linear-gradient(90.2deg, #E89EDE -18.53%, #AC01E9 82.15%);
$gradient-footer: linear-gradient(180deg, #3E3964 0%, #3E3964 0.01%, #1E1357 100%);
$gradient-offer: linear-gradient(to bottom, rgba(225,75,124,0.882) 0%,rgba(221,88,224,0.867) 31.49%);
$gradient-inst-buttons: linear-gradient(90deg, #E14B7C 1.47%, #BA44F2 53.03%, #AB00E8 98.53%);;
$gradient-inst-text: linear-gradient(to bottom, rgba(172,1,233,0.675) 0%,rgba(225,75,124,0.882) 100%);

$gradient-inst: linear-gradient(to bottom, rgba(246,89,99,0.967) 0%,rgba(190,44,193,0.747) 100%);
$gradient-start-button: linear-gradient(180deg, #FFFFFF 0%, #DAE0FF 100%);


$gradient-blue-4-blue2: linear-gradient(180deg, #6F4EDE 0%, #5C9FF1 100%);

$gradient-info-card-pro: linear-gradient(180deg, #6F4EDE 0%, #80C4FB 100%);
$gradient-info-card-free: $gray-3;










:export {
  white: $white;
  black: $gray-10;
  purple4: $purple-4;
  purple6: $purple-6;
  gray1: $gray-1;
  gray2: $gray-2;
  gray9: $gray-9;
  red5: $red-5;
  red6: $red-6;
  blue1: $blue-1;
  blue2: $blue-2;
  blue4: $blue-4;
  blue3: $blue-3;
  blue6: $blue-6;
  blue10: $blue-10;
  backgroundColor: $backgroundColor;
  blue3Gray1: $blue-3-gray-1;
  blue3Purple4: $blue-2-purple-4;
  infoCardFree: $info-card-free;
  blue2Purple4: $blue-2-purple-4;
  gradientNft: $gradient-nft;
  gradientInfo: $gradient-info;
  gradientFooter: $gradient-footer;
  gradientOffer: $gradient-offer;
  gradientInstButtons: $gradient-inst-buttons;
  gradientStartButton: $gradient-start-button;
  gradientInstText: $gradient-inst-text;
  gradientInfoCardPro: $gradient-info-card-pro;
  gradientInfoCardFree: $gradient-info-card-free;
  gradientInst: $gradient-inst;
  gray10: $gray-10;
  gray8: $gray-8;
  white50: $white-50;
  gray7: $gray-7;
  gray6: $gray-6;
  gray5: $gray-5;
  gray4: $gray-4;
  gray3: $gray-3;
  green10: $green-10;
  green9: $green-9;
  green8: $green-8;
  green7: $green-7;
  green6: $green-6;
  green5: $green-5;
  green4: $green-4;
  green3: $green-3;
  green2: $green-2;
  green1: $green-1;
}
