.page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    min-height: calc(100vh - 154px - 87px);
  }
}
